<template>
  <main>
    <section
      class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-x-8 gap-y-6"
    >
      <div
        class="
          shadow-md
          rounded
          bg-white
          py-8
          px-8
          flex flex-col
          justify-center
          text-center
          items-center
          relative
          card
        "
        v-for="(user, index) in users"
        :key="index"
      >
        <div class="my-4">
          <user-icon :width="'w-16'" :height="'h-16'"></user-icon>
        </div>
        <h1 class="text-lg font-medium leading-6">{{ user.name }}</h1>
        <p
          v-if="user.isApproved == false"
          class="text-gray-500 px-1 py-1 rounded mt-2"
        >
          Not Approved
        </p>
        <p
          v-if="user.isApproved == true"
          class="text-gray-500 px-1 py-1 rounded mt-2 bg-active_pill_color"
        >
          Approved
        </p>
        <div class="absolute top-0 h-1/5 w-full card-top-bar">
          <div class="flex justify-between px-4 py-3">
            <div>
              <div @click="roleOption(index)" class="cursor-pointer">
                <dotted-menu :color="'text-black'"></dotted-menu>
              </div>
              <custom-role-option
                v-if="role_option === index"
                @selected-role="selectedRole"
                @close-option="closeOption"
              ></custom-role-option>
            </div>
            <a
              href="javascript:;"
              class="hover:text-primary"
              :class="{
                'text-gray-200 cursor-not-allowed': role == null,
                'text-black cursor-pointer': role != null
              }"
              @click="changeStaus(user.id)"
            >
              Approve staff
            </a>
          </div>
        </div>
      </div>
    </section>
    <section>
      <loading-model v-if="loading"></loading-model>
    </section>
  </main>
</template>

<script>
import { mapState } from 'vuex'
import userIcon from '../Icons/userIcon.vue'
import Swal from 'sweetalert2'
import LoadingModel from '../Utilities/loadingModel.vue'
import DottedMenu from '../Icons/dottedMenu.vue'
import CustomRoleOption from './CustomRoleOption.vue'

export default {
  components: { userIcon, LoadingModel, DottedMenu, CustomRoleOption },
  data() {
    return {
      role_option: null,
      role: null
    }
  },
  created() {
    // this.$store.dispatch('users_list/getPending')
  },

  computed: mapState('users_list', ['users', 'loading']),

  watch: {
    role(val) {
      if (val != undefined) {
        this.role = val
      } else if (val == undefined) {
        this.role = null
      }
    }
  },

  methods: {
    roleOption(val) {
      if (val == this.role_option) {
        this.role_option = null
      } else {
        this.role_option = val
      }
    },

    closeOption() {
      this.role_option = null
    },

    selectedRole(val) {
      if (val != undefined) {
        this.role = val
      } else if (val == undefined) {
        this.role = null
      }
    },

    changeStaus(id) {
      var update = {
        id,
        role: this.role
      }
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `yes`
      }).then(result => {
        if (result.isConfirmed) {
          Swal.fire('Saved!', '', 'success')
          this.$store.dispatch('users_list/updateStatus', update)
        } else if (result.isDenied) {
          Swal.fire('Changes are not saved', '', 'info')
        }
      })
    }
  },

  mounted() {
    this.$store.dispatch('users_list/getPending')
  }
}
</script>

<style></style>
