<template>
  <div>
    <div class="relative">
      <div
        class="
          absolute
          bg-white
          border border-gray-50
          rounded
          shadow-md
          left-0
          text-xs
          w-40
          py-2
          text-justify
        "
      >
        <div
          class="py-1 px-4 hover:bg-gray-200 hover:shadow-inner cursor-pointer"
          @click="ceoRole"
        >
          Ceo
        </div>
        <div
          class="py-1 px-4 hover:bg-gray-200 hover:shadow-inner cursor-pointer"
          @click="fdoRole"
        >
          Front Desk Officer
        </div>
        <div
          class="py-1 px-4 hover:bg-gray-200 hover:shadow-inner cursor-pointer"
          @click="doctorRole"
        >
          Doctor
        </div>
        <div
          class="py-1 px-4 hover:bg-gray-200 hover:shadow-inner cursor-pointer"
          @click="labRole"
        >
          Laboratory technician
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      role_1: null,
      role_2: null,
      role_3: null,
      role_4: null
    }
  },
  watch: {
    role_1(val) {
      if (val == 1) {
        this.role_1 = val
      } else if (val == null) {
        this.role_1 = null
      }
    },
    role_2(val) {
      if (val == 2) {
        this.role_2 = val
      } else if (val == null) {
        this.role_2 = null
      }
    },
    role_3(val) {
      if (val == 3) {
        this.role_3 = val
      } else if (val == null) {
        this.role_3 = null
      }
    },
    role_4(val) {
      if (val == 4) {
        this.role_4 = val
      } else if (val == null) {
        this.role_4 = null
      }
    }
  },

  methods: {
    ceoRole() {
      if (this.role_4 === null || this.role_4 == '') {
        this.role_4 = 4
        this.$emit('selected-role', this.role_4)
        this.$emit('close-option')
      }
    },
    fdoRole() {
      if (this.role_3 === null || this.role_3 == '') {
        this.role_3 = 3
        this.$emit('selected-role', this.role_3)
        this.$emit('close-option')
      }
    },
    doctorRole() {
      if (this.role_2 === null || this.role_2 == '') {
        this.role_2 = 2
        this.$emit('selected-role', this.role_2)
        this.$emit('close-option')
      }
    },
    labRole() {
      if (this.role_1 === null || this.role_1 == '') {
        this.role_1 = 1
        this.$emit('selected-role', this.role_1)
        this.$emit('close-option')
      }
    }
  }
}
</script>

<style></style>
