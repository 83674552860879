<template>
  <div>
    <section class="my-24">
      <section class="flex justify-between items-center mb-12">
        <section>
          <h1 class="text-xl font-medium">Staff</h1>
        </section>
        <section class="flex align-middle items-center">
          <select
            name=""
            id=""
            class="h-8 w-40 px-2 rounded shadow bg-gray-200 mr-4 outline-none"
            v-model="status"
          >
            <option value="1">Approved</option>
            <option value="0">Pending</option>
          </select>
        </section>
      </section>
      <approved-staff v-if="approved"></approved-staff>
      <pending v-if="pending"></pending>
    </section>
  </div>
</template>

<script>
import ApprovedStaff from './ApprovedStaff.vue'
import Pending from './Pending.vue'
export default {
  components: { ApprovedStaff, Pending },

  data() {
    return {
      status: 1,
      approved: true,
      pending: false
    }
  },

  watch: {
    status(val) {
      if (val == 1) {
        this.approved = true
        this.pending = false
      } else if (val == 0) {
        this.pending = true
        this.approved = false
      }
    }
  }
}
</script>

<style></style>
