<template>
  <main>
    <section
      class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-x-8 gap-y-6"
    >
      <div
        class="
          shadow-md
          rounded
          bg-white
          py-8
          px-8
          flex flex-col
          justify-center
          text-center
          items-center
          relative
          card
        "
        v-for="(user, index) in users"
        :key="index"
      >
        <div class="my-4">
          <user-icon :width="'w-16'" :height="'h-16'"></user-icon>
        </div>
        <h1 class="text-lg font-medium leading-6">{{ user.name }}</h1>
        <p
          v-if="user.isApproved == false"
          class="text-gray-500 px-1 py-1 rounded mt-2"
        >
          Not Approved
        </p>
        <p
          v-if="user.isApproved == true"
          class="text-gray-500 px-1 py-1 rounded mt-2 bg-active_pill_color"
        >
          Approved
        </p>
        <div class="absolute top-0 h-1/5 w-full card-top-bar">
          <div class="flex justify-between px-4 py-3">
            <a
              href="javascript:;"
              class="hover:text-primary"
              @click="changeStaus(user.id)"
            >
              Disable Staff
            </a>
          </div>
        </div>
      </div>
    </section>
    <section>
      <loading-model v-if="loading"></loading-model>
    </section>
  </main>
</template>

<script>
import { mapState } from 'vuex'
import userIcon from '../Icons/userIcon.vue'
import Swal from 'sweetalert2'
import LoadingModel from '../Utilities/loadingModel.vue'
export default {
  components: { userIcon, LoadingModel },
  created() {
    // this.$store.dispatch('users_list/getApproved')
  },

  computed: mapState('users_list', ['users', 'loading']),

  methods: {
    changeStaus(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `yes`
      }).then(result => {
        if (result.isConfirmed) {
          Swal.fire('Saved!', '', 'success')
          this.$store.dispatch('users_list/disableStatus', id)
        } else if (result.isDenied) {
          Swal.fire('Changes are not saved', '', 'info')
        }
      })
    }
  },

  mounted() {
    this.$store.dispatch('users_list/getApproved')
  }
}
</script>

<style></style>
