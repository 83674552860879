<template>
  <master-layout :on_active="on_active" v-if="user.role >= 3" class="relative">
    <main class="mx-auto w-87 pb-11">
      <index></index>
      <client-form
        v-if="show_modal"
        @close-card-modal="createCard"
      ></client-form>

      <section
        class="absolute bottom-6 left-6 xl:left-96"
        v-if="new_app_version"
        @click="updateVersion"
      >
        <div class="cursor-pointer">
          <div
            class="
              bg-primary
              animate-bounce
              shadow-md
              hover:shadow-xl
              px-4
              py-4
              text-white text-sm
              rounded-md
            "
          >
            New Version Available! Click to update
          </div>
        </div>
      </section>
    </main>
  </master-layout>
</template>

<script>
import ClientForm from '../components/Create/ClientForm.vue'
import MasterLayout from '../components/Layout/MasterLayout.vue'
import Index from '../components/Staff/index.vue'
export default {
  components: { MasterLayout, Index, ClientForm },
  props: {
    on_active: {
      default: 'staff',
      type: String
    }
  },

  data() {
    return {
      show_modal: false
    }
  },

  computed: {
    user() {
      return this.$store.state.user
    },

    new_app_version() {
      return this.$store.state.new_app_version
    }
  },

  methods: {
    createCard() {
      this.show_modal = !this.show_modal
    },

    updateVersion() {
      this.$store.dispatch('addAppVersionToLocalStorage')
    }
  },

  beforeMount() {
    this.$store.dispatch('middleware/isLogged')
  },

  mounted() {
    this.$store.dispatch('appVersion')
  }
}
</script>

<style></style>
